import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from '../MobileMenu/MobileMenu';
import Logo from '../../images/logo.png';
import emailjs from 'emailjs-com';

const Header2 = (props) => {
    const [menuActive, setMenuState] = useState(false);
    const [SearchActive, setSearchState] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const SubmitHandler = (e) => {
        e.preventDefault();
        emailjs.send('service_wwh6av5', 'template_qefeo44', formData, 'sIgedgIeVFkZu589z')
            .then((result) => {
                alert('Message sent successfully!');
            }, (error) => {
                alert('An error occurred, please try again.');
            });
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    return (
        <header className="main-header s2">
            <div className="header-upper">
                <div className="container-fluid clearfix">
                    <div className="header-inner">
                        <div className="logo"><Link onClick={ClickHandler} to="/"><img src={Logo} alt="" /></Link></div>
                        <div className="nav-outer clearfix">
                            <nav className="main-menu navbar-expand-lg">
                                <MobileMenu />
                                <div className="navbar-collapse collapse clearfix">
                                    <ul className="navigation clearfix">
                                        <li><Link onClick={ClickHandler} to="/">Home</Link></li>
                                        <li><Link onClick={ClickHandler} to="/about">About</Link></li>
                                        <li><Link onClick={ClickHandler} to="/property">Projects</Link></li>
                                        <li><Link onClick={ClickHandler} to="/services">Services</Link></li>
                                        <li><Link onClick={ClickHandler} to="/contact">Contact us</Link></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>

                        <div className="menu-icons">
                            <div className="menu-sidebar">
                                <button onClick={() => setMenuState(!menuActive)}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`sidebar-wrap ${menuActive ? "side-content-visible" : ""}`}>
                <div className="form-back-drop" onClick={() => setMenuState(!menuActive)}></div>
                <section className='hidden-bar'>
                    <div className="inner-box text-center">
                        <div className="cross-icon" onClick={() => setMenuState(!menuActive)}><span className="fa fa-times"></span></div>
                        <div className="title">
                            <h3>Get Appointment</h3>
                        </div>
                        <div className="appointment-form">
                            <form onSubmit={SubmitHandler}>
                                <div className="form-group">
                                    <input type="text" name="name" placeholder="Name" required onChange={handleChange} value={formData.name} />
                                </div>
                                <div className="form-group">
                                    <input type="email" name="email" placeholder="Email Address" required onChange={handleChange} value={formData.email} />
                                </div>
                                <div className="form-group">
                                    <input type="text" name="phone" placeholder="Phone no." required onChange={handleChange} value={formData.phone} />
                                </div>
                                <div className="form-group">
                                    <textarea name="message" placeholder="Message" rows="5" onChange={handleChange} value={formData.message}></textarea>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="theme-btn">Submit now</button>
                                </div>
                            </form>
                        </div>
                        <div className="social-icons">
                            <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-tiktok"></i>
                            </a>
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-facebook"></i>
                            </a>
                            <a href="https://www.instagram.com/markapbs/" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-linkedin"></i>
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </header>
    );
}

export default Header2;
