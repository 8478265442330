import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const Plans = ({ planImages = [] }) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const planData = [
        { id: '1', title: 'Building Plan' },
        { id: '2', title: 'Render' },
        { id: '3', title: 'Furniture Project' },
        { id: '4', title: 'Implementation' }
    ];

    return (
        <section className="apartment-plan text-center mt-135">
            <div className="container">
                <div className="section-title mb-50">
                    <h2>Project <span className="thin">Plans</span></h2>
                </div>
                <div className="apartment-tab">
                    <Nav tabs className="justify-content-center mb-65">
                        {planData.map((plan) => (
                            <NavItem key={plan.id}>
                                <NavLink
                                    className={classnames({ active: activeTab === plan.id })}
                                    onClick={() => toggle(plan.id)}
                                >
                                    {plan.title}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {planData.map((plan, index) => (
                            <TabPane tabId={plan.id} key={plan.id}>
                                <h3>{plan.title}</h3>
                                {/* Check if the image exists before displaying */}
                                {planImages[index] ? (
                                    <img src={planImages[index]} alt={`${plan.title}`} />
                                ) : (
                                    <p>Image not available</p>
                                )}
                                <div className="row text-left justify-content-center mt-40">
                                    {/* Additional details if needed */}
                                </div>
                            </TabPane>
                        ))}
                    </TabContent>
                </div>
            </div>
        </section>
    );
};

export default Plans;
