import simg from '../images/services/icon1.png';
import simg2 from '../images/services/icon2.png';
import simg3 from '../images/services/icon3.png';
import simg4 from '../images/services/icon4.png';
import simg5 from '../images/services/icon5.png';
import simg6 from '../images/services/icon6.png';

import cConceptimg1 from '../images/service-single/concept-planning/concept1.jpeg';
import cConceptimg2 from '../images/service-single/concept-planning/concept2.jpeg';
import cConceptimg3 from '../images/service-single/concept-planning/concept.3.jpeg';

import fFurnitureimg1 from '../images/service-single/furniture-decor/furniture1.jpeg';
import fFurnitureimg2 from '../images/service-single/furniture-decor/furniture2.jpeg';
import fFurnitureimg3 from '../images/service-single/furniture-decor/furniture3.jpeg';

import dDesignimg1 from '../images/service-single/design-panning/desing1.jpg';
import dDesignimg2 from '../images/service-single/design-panning/design2.jpg';
import dDesignimg3 from '../images/service-single/design-panning/design3.jpg';

import cCustom1 from '../images/service-single/custom-solutions/custom2.jpg';
import cCustom2 from '../images/service-single/custom-solutions/custom1.jpg';
import cCustom3 from '../images/service-single/custom-solutions/custom3.jpg';

import eExterior1 from '../images/service-single/exterior-design/exterior1.jpg';
import eExterior2 from '../images/service-single/exterior-design/exterior2.jpg';
import eExterior3 from '../images/service-single/exterior-design/exterior33.jpg';

import iInterior1 from '../images/service-single/interior-design/interior1.jpg';
import iInterior2 from '../images/service-single/interior-design/interior2.jpg';
import iInterior3 from '../images/service-single/interior-design/interior3.jpg';




const Services = [
    {
        Id: '1',
        sImg: simg,
        sSimg: dDesignimg1,
        sTitle: 'Design & Planning',
        sSubtitle1: 'Our design and planning services blend innovation and practicality to bring your vision to life. We work closely with clients to develop thoughtful, well-rounded plans that meet aesthetic, functional, and budgetary requirements.',
        sSubtitle2: 'From initial concept development to detailed planning, we guide you through each stage, ensuring that your project aligns with your goals and adheres to industry standards. Our collaborative approach and attention to detail result in outstanding designs that set the foundation for successful project execution.',
        slug: 'Design-&-Planning',
        description: 'Creating comprehensive design and planning strategies tailored to meet client expectations.',
        des2: 'We ensure each phase of design aligns seamlessly with project goals.',
        des3: 'Our team excels in strategy, design iteration, and efficient project execution.',
        ssImg1: dDesignimg2,
        ssImg2: dDesignimg3,
        sOurCapabilities: 'With extensive experience in design and planning, we deliver customized solutions that blend creativity with functionality. Our capabilities include comprehensive project assessments, innovative design, and strategic planning.',
        sListOfCapabilities: (
            <ul>
                <li>Client Consultation and Vision Analysis</li>
                <li>Conceptual Design and Development</li>
                <li>Detailed Planning and Blueprint Creation</li>
                <li>Design Iteration and Refinement</li>
                <li>Project Oversight and Management</li>
            </ul>
        ),
        sOurAproach: 'We prioritize client collaboration and continuous feedback throughout the design and planning process. By maintaining open communication and detailed attention, we ensure the final design is both innovative and practical.',
        sWorkProgress: (
            <ul>
                <li>Initial Consultation and Vision Analysis</li>
                <li>Concept Creation</li>
                <li>Detailed Planning</li>
                <li>Feedback and Refinement</li>
                <li>Execution and Project Management</li>
            </ul>
        ),
    },
    {
        Id: '2',
        sImg: simg2,
        sSimg: cCustom1,
        sTitle: 'Custom Solutions',
        sSubtitle1: 'Our custom solutions are designed to meet the specific needs and challenges of each client. We conduct a thorough analysis to understand the project requirements and develop a tailored approach that ensures successful outcomes.',
        sSubtitle2: 'From initial research and concept development to prototyping and implementation, our team prioritizes continuous feedback and iteration to refine the solution and maximize its effectiveness.',
        slug: 'Custom-Solutions',
        description: 'Delivering customized strategies that cater to unique client challenges.',
        des2: 'We excel in creating tailored solutions that enhance project success.',
        des3: 'Our approach focuses on thorough analysis, prototyping, and continuous refinement.',
        ssImg1: cCustom2,
        ssImg2: cCustom3,
        sOurCapabilities: 'Our expertise in custom solutions is centered on innovation and strategic problem-solving. By integrating advanced technology and creative thinking, we develop solutions that align with client goals and deliver maximum value.',
        sListOfCapabilities: (
            <ul>
                <li>Needs Assessment and Project Analysis</li>
                <li>Custom Strategy Development</li>
                <li>Prototyping and Testing</li>
                <li>Feedback Integration and Iterative Improvements</li>
                <li>Solution Implementation and Review</li>
            </ul>
        ),
        sOurAproach: 'We begin by assessing the unique needs of each project, followed by strategy development and iterative prototyping. Our process is refined through continuous feedback to ensure optimal performance and results.',
        sWorkProgress: (
            <ul>
                <li>Comprehensive Needs Analysis</li>
                <li>Custom Strategy Creation</li>
                <li>Prototyping and Testing</li>
                <li>Feedback and Iterative Refinement</li>
                <li>Final Implementation</li>
            </ul>
        ),
    },
    {
        Id: '3',
        sImg: simg3,
        sSimg: fFurnitureimg1,
        sTitle: 'Furniture & Decor',
        sSubtitle1: 'We believe that furniture and decor should not only beautify a space but also enhance its functionality. Our team provides expert advice and custom solutions for selecting and arranging furniture and decor that align with your style and practical needs.',
        sSubtitle2: 'Our services include everything from design consultation and custom furniture creation to coordinating installations. We ensure that every piece complements the overall aesthetic of the space, creating an environment that is cohesive and inviting.',
        slug: 'Furniture-&-Decor',
        description: 'Crafting functional and aesthetically pleasing furniture and decor solutions.',
        des2: 'Our designs emphasize style, quality, and functionality.',
        des3: 'From concept to completion, we ensure each piece adds value to the space.',
        ssImg1: fFurnitureimg2,
        ssImg2: fFurnitureimg3,
        sOurCapabilities: 'We offer a curated selection of high-quality furniture and decor items, alongside custom design services to fit any client’s vision. Our team ensures that each piece enhances the space’s aesthetics and practicality.',
        sListOfCapabilities: (
            <ul>
                <li>Design Consultation and Planning</li>
                <li>Custom Furniture Design</li>
                <li>Selection of Quality Materials and Pieces</li>
                <li>Coordination and Installation</li>
                <li>Final Styling and Finishing Touches</li>
            </ul>
        ),
        sOurAproach: 'Our approach to furniture and decor is holistic and client-focused. We begin with a detailed consultation to understand the client’s vision, followed by sourcing, custom design, and meticulous installation.',
        sWorkProgress: (
            <ul>
                <li>Design Consultation</li>
                <li>Custom Design and Selection</li>
                <li>Installation Coordination</li>
                <li>Final Styling</li>
            </ul>
        ),
    },
    {
        Id: '4',
        sImg: simg4,
        sSimg: eExterior1,
        sTitle: 'Exterior Design',
        sSubtitle1: 'Our exterior design services transform outdoor spaces into beautiful, functional environments. We use a strategic approach that combines landscaping, hardscaping, and outdoor furniture selection to enhance both aesthetics and usability.',
        sSubtitle2: 'From the initial site assessment to the final execution, we work closely with clients to create outdoor areas that align with their vision. Our designs prioritize sustainability, practicality, and visual appeal, ensuring an outdoor space that stands out.',
        slug: 'Exterior-Design',
        description: 'Transforming outdoor areas into functional, beautiful spaces.',
        des2: 'We incorporate client vision and site potential for impactful exterior designs.',
        des3: 'Our team handles everything from landscaping to lighting for a cohesive result.',
        ssImg1: eExterior2,
        ssImg2: eExterior3,
        sOurCapabilities: 'Our exterior design capabilities include comprehensive landscaping, hardscaping, and outdoor lighting plans that enhance the space’s aesthetic and functionality.',
        sListOfCapabilities: (
            <ul>
                <li>Site Assessment and Feasibility Analysis</li>
                <li>Comprehensive Landscaping and Hardscaping</li>
                <li>Outdoor Furniture Selection</li>
                <li>Lighting Design and Implementation</li>
                <li>Coordination and Installation</li>
            </ul>
        ),
        sOurAproach: 'We start with a thorough site assessment to understand the client’s vision and site constraints. Our team then creates detailed plans that include landscaping, hardscaping, and lighting to enhance both aesthetics and functionality.',
        sWorkProgress: (
            <ul>
                <li>Site Assessment</li>
                <li>Landscaping and Hardscaping Design</li>
                <li>Furniture and Lighting Selection</li>
                <li>Implementation and Installation</li>
                <li>Final Touches</li>
            </ul>
        ),
    },
    {
        Id: '5',
        sImg: simg5,
        sSimg: iInterior1,
        sTitle: 'Interior Design',
        sSubtitle1: 'Our interior design services focus on creating spaces that are both beautiful and functional. We work closely with clients to understand their style, preferences, and practical needs, turning their vision into reality.',
        sSubtitle2: 'Our process includes space planning, selecting materials and finishes, furniture and decor placement, and project management. We ensure that each design aligns with client expectations and enhances their daily living experience.',
        slug: 'Interior-Design',
        description: 'Crafting interior spaces that inspire and elevate everyday life.',
        des2: 'We blend aesthetics and practicality to create exceptional interiors.',
        des3: 'From planning to styling, we manage each detail for a seamless finish.',
        ssImg1: iInterior2,
        ssImg2: iInterior3,
        sOurCapabilities: 'Our interior design capabilities range from space planning and material selection to comprehensive project coordination. We focus on creating cohesive, stylish, and functional interiors that reflect the client’s vision.',
        sListOfCapabilities: (
            <ul>
                <li>Client Consultation and Space Analysis</li>
                <li>Space Planning and Layout Optimization</li>
                <li>Material and Finish Selection</li>
                <li>Furniture and Decor Placement</li>
                <li>Project Management and Coordination</li>
            </ul>
        ),
        sOurAproach: 'We begin with a detailed consultation to capture the client’s vision and requirements. Our team then develops a tailored plan that incorporates aesthetics, functionality, and comfort, ensuring a seamless and stylish result.',
        sWorkProgress: (
            <ul>
                <li>Initial Consultation</li>
                <li>Space Planning and Layout</li>
                <li>Material and Furniture Selection</li>
                <li>Installation and Styling</li>
                <li>Project Completion</li>
            </ul>
        ),
    },
    {
        Id: '6',
        sImg: simg6,
        sSimg: cConceptimg1,
        sTitle: 'Concept Planning',
        sSubtitle1: 'Concept planning is essential to set the foundation for any successful project. Our team collaborates with clients to brainstorm, develop, and refine initial ideas, ensuring that they align with project goals and market trends.',
        sSubtitle2: 'Once a concept is developed, we move forward with strategic planning and prototyping. Our iterative process incorporates feedback and testing, resulting in a well-defined concept ready for execution.',
        slug: 'Concept-Planning',
        description: 'Turning innovative ideas into actionable plans through strategic concept planning.',
        des2: 'Our process ensures concepts are both innovative and feasible.',
        des3: 'From brainstorming to finalization, we manage each step for successful results.',
        ssImg1: cConceptimg2,
        ssImg2: cConceptimg3,
        sOurCapabilities: 'We are skilled in transforming initial ideas into comprehensive plans through strategic brainstorming, market analysis, and feasibility studies. Our focus is to create concepts that are impactful and ready for development.',
        sListOfCapabilities: (
            <ul>
                <li>Idea Generation and Brainstorming</li>
                <li>Market Research and Analysis</li>
                <li>Feasibility Studies</li>
                <li>Concept Development and Prototyping</li>
                <li>Feedback and Iterative Refinement</li>
            </ul>
        ),
        sOurAproach: 'We approach concept planning with an emphasis on collaboration and strategic thinking. From initial brainstorming to final testing, we involve clients at every step to create well-rounded, actionable concepts.',
        sWorkProgress: (
            <ul>
                <li>Initial Brainstorming</li>
                <li>Market and Feasibility Analysis</li>
                <li>Concept Development</li>
                <li>Feedback and Refinement</li>
                <li>Final Plan Creation</li>
            </ul>
        ),
    },
];

export default Services;
