import React from 'react'
import { Link } from 'react-router-dom'
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import Bg from '../../images/footer.png'
import pImg1 from '../../images/instagram/instagram7.png'
import pImg2 from '../../images/instagram/instagram8.png'
import pImg3 from '../../images/instagram/instagram9.png'
import pImg4 from '../../images/instagram/instagram10.png'
import pImg5 from '../../images/instagram/instagram11.png'
import pImg6 from '../../images/instagram/instagram12.png'
import CtaSection from '../CtaSection/CtaSection';


const images = [
    pImg1,
    pImg2,
    pImg3,
    pImg4,
    pImg5,
    pImg6,
]

const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [open, setOpen] = React.useState(false);

    return (
        <div>
            <CtaSection/>
            <footer className="footer-section pt-220" style={{ backgroundImage: `url(${Bg})` }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="instagram-posts">
                                {images.map((item, pitem) => (
                                    <div className="instagram-item" key={pitem}>
                                        <img src={item} alt="Instagram" onClick={() => setOpen(true)} />
                                        <div className="instagram-overlay">
                                            <span onClick={() => setOpen(true)}><i className="fa fa-instagram"></i></span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-4">
                            <div className="contact-widget">
                                <h3>Contact</h3>
                                <h6>Find out all the ways to enjoy furniture around the world.</h6>
                                <h6>A: 82C Str Căminului, Manolache, București </h6>
                                <h6>T: 0721 212 212</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom mt-55">
                    <div className="container">
                    <div className="bottom-inner">
                        <div className="social-icons">
                            <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-tiktok"></i>
                            </a>
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-facebook"></i>
                            </a>
                            <a href="https://www.instagram.com/markapbs/" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-linkedin"></i>
                            </a>
                        </div>
                        <div className="copyright">
                            <p>© Copyright 2024 by <Link to="/">Markap</Link></p>
                        </div>
                    </div>

                    </div>
                </div>
                <Lightbox
                    open={open}
                    close={() => setOpen(false)}
                    style={{ maxWidth: 190, maxHeight: 190 }}
                    slides={[
                        { src: pImg1 },
                        { src: pImg2 },
                        { src: pImg3 },
                        { src: pImg4 },
                        { src: pImg5 },
                        { src: pImg6 },
                    ]}
                    plugins={[Zoom]}
                />
            </footer>
        </div>
    )
}

export default Footer;