import React, { useState } from 'react';
import fImg from '../../images/faq/faq-right-bg.png'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';



const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (

        <section className="faq-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 pr-125 rpr-15">
                        <div className="faq-left-image"></div>
                    </div>
                    <div className="col-lg-6 pl-0">
                        <div className="faq-accordion ras-content pt-95 pb-110 rpb-0 rmb-100">
                            <div className="section-title mb-35">
                                <h6>Best Architecture</h6>
                                <h2>Architecture did  expect
                                    <span className="thin">strategist</span>
                                </h2>
                            </div>
                            <div id="accordion">
                                <Accordion open={open} toggle={toggle}>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="1" className='card-header'>Core Architeture</AccordionHeader>
                                        <AccordionBody accordionId="1">
                                        Our core architecture services focus on delivering innovative and efficient design solutions that integrate both form and function. We ensure each project meets the highest quality standards while reflecting the client's unique vision and needs.
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="2" className='card-header'>Offices</AccordionHeader>
                                        <AccordionBody accordionId="2">
                                        Our office design solutions create spaces that enhance productivity, comfort, and collaboration. We tailor each design to incorporate modern amenities, natural light, and ergonomic layouts, resulting in environments that inspire teams and support business goals.
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="3" className='card-header'>Shops</AccordionHeader>
                                        <AccordionBody accordionId="3">
                                        Designing shops, from large supermarkets like Lidl to home improvement stores like Leroy Merlin, requires a strategic approach to space planning and customer experience. Our designs focus on clear navigation, product accessibility, and visual appeal, ensuring an engaging and efficient shopping environment.
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="4" className='card-header'>Restaurants</AccordionHeader>
                                        <AccordionBody accordionId="4">
                                        Our restaurant design services blend ambiance, functionality, and customer experience. We create inviting spaces that optimize seating arrangements, ensure smooth traffic flow, and incorporate brand identity, resulting in a memorable dining experience.
                                        </AccordionBody>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                        <div className="faq-right-bg">
                            <img src={fImg} alt="FAQ" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default FaqSection;