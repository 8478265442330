import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import { useParams } from 'react-router-dom';
import Project from '../../api/Projects';
import Plans from './Plans';
import Footer from '../../components/footer/Footer';

const ProjectSinglePage = () => {
    const { slug } = useParams();

    // Find the project by slug
    const projectDetails = Project.find(item => item.slug === slug);

    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={projectDetails.pTitle} pagesub={'Project'} />
            <div className="project-single-area section-padding">
                <div className="property-single mt-150">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="property-single-left">
                                    <div className="property-left-content">
                                        <h3><span className="font-weight-light">A Modern Development</span></h3>
                                        <ul>
                                            <li>Wellness & Spa: A tranquil space dedicated to relaxation and rejuvenation.</li>
                                            <li>Conference Rooms: Equipped with modern AV facilities for corporate events and meetings.</li>
                                            <li>Restaurant: Fine dining options with a range of international cuisines.</li>
                                            <li>Fitness Center: High-end gym with personal trainers and modern equipment.</li>
                                            <li>Library: Quiet space with a vast collection of books and digital resources.</li>
                                            <li>Rooftop Bars: Relaxing spaces offering panoramic views and cocktails.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="property-single-right">
                                    <div className="property-right-content pl-40 rpl-0">
                                        <h3 className="border-text">Key Facilities:</h3>
                                        <h3>Project Park</h3>
                                        <h3>Gallery</h3>
                                        <h3>Retail Shops</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Pass the planImages array as a prop to the Plans component */}
                <Plans planImages={projectDetails.planImages} />
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default ProjectSinglePage;
