import React from 'react';
import ContactForm from '../ContactFrom'
import sIcon1 from '../../images/contact/icon1.png'
import sIcon2 from '../../images/contact/icon2.png'
import sIcon3 from '../../images/contact/icon3.png'


const Contactpage = () => {

    return (
        <div>
            <div class="contact-map" id="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2850.2909032812254!2d26.2370042!3d44.406676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1fc269db28ebf%3A0x395a245afbf2b9c1!2sMarkap%20Business%20Solutions%20SRL!5e0!3m2!1sro!2sro!4v1718620114611!5m2!1sro!2sro"></iframe>
            </div>
            <div className="contact-info text-center mb-110">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="info-item">
                            <img src={sIcon1} alt="Icon"/>
                                <p>Strada Căminului 82, Ilfov, România</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="info-item">
                            <img src={sIcon2} alt="Icon"/>
                                <p>office@markap.ro <br/>0721 212 212</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="info-item">
                            <img src={sIcon3} alt="Icon"/>
                                <p>Web Address: <br/>markap.ro</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactForm/>
        </div>
    )

}

export default Contactpage;
